<!--更多展览-->
<template>
  <div class="partyDiv">
    <!--面包屑-->
    <el-row class="outerRow">
      <el-col>
        <top-path-list :path-obj="pathObj"></top-path-list>
      </el-col>
    </el-row>
    <!--内容部分-->
    <div class="news-div">
      <!--<div class="rightTopDiv">
        <img src="@/assets/img/giEdifice/view.svg" alt="" class="imgClass">
        <span class="rightTopBtn cursorPointer" @click="switchList('all')" v-show="type === ''">
          查看往届展览
        </span>
        <span class="rightTopBtn cursorPointer" @click="switchList('')" v-show="type === 'all'">
          查看最新展览
        </span>
      </div>-->
      <div class="notice-item" v-for="(item, index) in showList" :key="index" @click="toDetail(item)">
        <div class="notice-item-img"><img :src="item.url" alt/></div>
        <div class="rightContent">
          <div class="notice-item-title" :title="item.infoName">{{ item.infoName }}</div>
          <div class="notice-item-content">
            <text-ellipsis :text="item.introduction" :height="50" style="width: 100%">
              <template slot="more">...</template>
            </text-ellipsis>
          </div>
          <div class="flexContainer">
            <div class="notice-item-date flexAlignItemCenter">
              <img src="@/assets/img/giEdifice/grayTimer.svg" alt="" class="imgClass">
              {{ item.showTime }}
            </div>
            <div class="notice-item-date flexAlignItemCenter">
              <img src="@/assets/img/giEdifice/addr.svg" alt="" class="imgClass" style="margin-left: 20px">
              {{ item.infoPosition }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--分页-->
    <div class="page">
      <el-pagination
        :page-sizes="[10, 20, 50, 100]"
        :page-size="pageSize"
        :current-page.sync="page"
        @current-change="pageChange"
        @size-change="handleSizeChange"
        @prev-click="prevClick"
        @next-click="nextClick"
        layout="total, sizes, prev, pager, next, jumper"
        :total="tableTotal">
      </el-pagination>
    </div>

    <!--增加取消下方白条-->
    <div style="height: 20px"></div>
  </div>
</template>

<script>
import topPathList from '@/components/topPathList'
import { echoImg } from '@/utils/commonApi.js'
import axios from "axios";
import textEllipsis from '@/components/textellipsis'
export default {
  name: "index",
  components: { topPathList, textEllipsis },
  data() {
    return {
      pathObj: [
        {
          name: '首页',
          path: '/'
        },
        {
          name: '地标大厦',
          path: '/giEdifice/index'
        },
        {
          name: '展览安排',
          path: '/giEdifice/moreShow'
        },
      ],
      showList: [],
      page: 1,
      // 总条数
      tableTotal: 0,
      pageSize: 10,
      type: 'all',
    }
  },
  methods: {
    // 条数变化
    handleSizeChange(val) {
      this.pageSize = val
      this.getList();
    },
    pageChange(val) {
      this.page = val
      this.getList();
    },
    // 上一页
    prevClick(val) {
      this.page = val
      this.getList()
    },
    // 下一页
    nextClick(val) {
      this.page = val
      this.getList()
    },
    getList() {
      let params = {
        current: this.page,
        size: this.pageSize,
        type: this.type,
      }
      axios({
        method: "get",
        url: process.env.VUE_APP_HUSSAR_DEFAULT_API + "/api/portal/exhibit/all",
        params: params,
      }).then(res => {
        if (res.data.code === 10000 || res.data.code === 200) {
          let data = res.data.data.records;
          if (data && data.length > 0) {
            data.forEach(item => {
              item.url = echoImg(item.coverImage)
            })
          }
          this.showList = data;
          this.tableTotal = res.data.data.total;
        } else {
          this.$message.error('查询失败')
        }
      }).catch(err => {
        this.$message.error('接口调用异常')
      })
    },
    // 查看历史全部
    switchList(type) {
      this.type = type;
      this.getList()
    },
    // 跳转详情
    toDetail(item) {
      this.$router.push({
        path: '/giEdifice/showDetail',
        query: {
          infoId: item.infoId,
        }
      });
    }
  },
  mounted() {
    this.getList()
  }
}
</script>

<style scoped lang="less">
@import '~@/assets/css/common.less';
  .partyDiv {
    text-align: center;
    background-color: #f8f9fa !important;
  }
  .brandTop {
    margin-left: 220px;
  }
  .news-div {
    min-height: 485px;
    .notice-item {
      width: 1200px;
      margin: 0px auto 29px;
      padding: 13px 16px;
      background: #FFFFFF;
      border-radius: 5px;
      height: 161px;
      .notice-item-img {
        float: left;
        img {
          width: 270px;
          height: 160px;
        }
      }
      .rightContent {
        text-align: left;
        margin-left: 311px;
        margin-top: 17px;
        .notice-item-title {
          font-size: 18px;
          font-family: SourceHanSansCN-Bold, SourceHanSansCN;
          font-weight: bold;
          color: #333333;
          line-height: 27px;
          overflow: hidden;//超出隐藏
          text-overflow: ellipsis;//显示省略号
          white-space: nowrap;//禁止文本自动换行
        }
        .notice-item-content {
          margin-top: 12px;
          text-align: left;
          font-size: 14px;
          font-family: SourceHanSansCN-Regular, SourceHanSansCN;
          font-weight: 400;
          color: #666666;
          line-height: 23px;
          display: -webkit-box;
          overflow: hidden;
          text-overflow: ellipsis;
          -webkit-box-orient: vertical;
        }
        .notice-item-date {
          margin-top: 18px;
          text-align: left;
          font-size: 14px;
          font-family: SourceHanSansCN-Regular, SourceHanSansCN;
          font-weight: 400;
          color: #ABB2BF;
          line-height: 21px;
        }
      }
    }
    .notice-item:hover {
      cursor: pointer;
    }
  }
  .page {
    margin-bottom: 20px;
  }
  .imgClass {
    width: 16px;
    height: 16px;
    margin-right: 10px;
  }
  .rightTopDiv {
    display: flex;
    align-items: center;
    justify-content: end;
    width: 1200px;
    margin: 0 auto 5px;
    height: 21px;
    .rightTopBtn {
      font-size: 14px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: #186CF5;
      line-height: 21px;
    }
  }

  @media screen and (max-width: 1366px) {
    .partyDiv {
      text-align: center;
      background-color: #f8f9fa !important;
    }
    .brandTop {
      margin-left: 0px;
    }
    .news-div {
      .notice-item {
        width: 1200px;
        margin: 0px auto 29px;
        padding: 13px 16px;
        background: #FFFFFF;
        border-radius: 5px;
        height: 161px;
        .notice-item-img {
          float: left;
          img {
            width: 270px;
            height: 160px;
          }
        }
        .rightContent {
          text-align: left;
          margin-left: 311px;
          margin-top: 17px;
          .notice-item-title {
            font-size: 18px;
            font-family: SourceHanSansCN-Bold, SourceHanSansCN;
            font-weight: bold;
            color: #333333;
            line-height: 27px;
            overflow: hidden;//超出隐藏
            text-overflow: ellipsis;//显示省略号
            white-space: nowrap;//禁止文本自动换行
          }
          .notice-item-content {
            margin-top: 12px;
            text-align: left;
            font-size: 14px;
            font-family: SourceHanSansCN-Regular, SourceHanSansCN;
            font-weight: 400;
            color: #666666;
            line-height: 23px;
            display: -webkit-box;
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-box-orient: vertical;
          }
          .notice-item-date {
            margin-top: 18px;
            text-align: left;
            font-size: 14px;
            font-family: SourceHanSansCN-Regular, SourceHanSansCN;
            font-weight: 400;
            color: #ABB2BF;
            line-height: 21px;
          }
        }
      }
      .notice-item:hover {
        cursor: pointer;
      }
    }
    .page {
      margin-bottom: 20px;
    }
  }
</style>
